<template>
  <v-container fluid class="bg-image">
    <v-btn fab color="primary" class="back-btn" @click="goToLogin()">
      <v-icon color="white" size="28px"> mdi-chevron-left </v-icon>
    </v-btn>
    <v-row justify="center" align="center" class="full-height my-5">
      <v-col lg="5" md="7" sm="9" cols="11" class="d-flex justify-center">
        <v-card
          v-if="!waiting_confirmation"
          elevation="2"
          class="signup-box py-8"
        >
          <div>
            <img src="../assets/img/logo_jangadeiro.png" />
          </div>
          <div class="signup-description">
            <p>Crie uma só conta para todos os serviços da Jangadeiro</p>
          </div>

          <v-form
            ref="form"
            v-model="valid"
            class="signup-form mx-5"
            lazy-validation
            @submit.prevent="signup()"
          >
            <v-text-field
              v-model="user.full_name"
              :rules="[rules.required]"
              label="Nome completo"
              rounded
              outlined
              required
            />

            <v-text-field
              v-model="user.username"
              :rules="[rules.minLength, rules.username]"
              label="Nome de usuário"
              rounded
              outlined
              required
            />

            <v-text-field
              v-model="user.email"
              :rules="[rules.email]"
              label="E-mail"
              hint="Use um e-mail real, pois você precisará confirmá-lo para acessar nossos serviços"
              rounded
              outlined
              required
            />

            <v-text-field
              v-model="user.cpf"
              :rules="[rules.required, rules.cpf]"
              label="CPF"
              v-mask="'###.###.###-##'"
              rounded
              outlined
              required
            />

            <v-text-field
              v-model="user.phone_number"
              :rules="[rules.required, rules.phone]"
              label="Número de telefone"
              v-mask="'(##) #####-####'"
              rounded
              outlined
              required
            />

            <v-select
              v-model="user.gender"
              :rules="[rules.required]"
              :items="genders"
              item-text="text"
              item-value="value"
              label="Gênero"
              rounded
              outlined
              required
            />

            <v-text-field
              v-model="user.password"
              :rules="[rules.minLength, rules.password]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Senha"
              rounded
              outlined
              required
              @click:append="showPassword = !showPassword"
            />

            <v-text-field
              v-model="user.passwordConfirmation"
              :rules="[rules.passwordConfirmation]"
              :type="showPassword ? 'text' : 'password'"
              label="Confirme a sua senha"
              rounded
              outlined
              required
            />

            <v-checkbox
              v-model="checkbox"
              :rules="[rules.required]"
              class="mt-n1 mb-4 ml-2"
            >
              <template v-slot:label>
                <div class="checkbox-text">
                  Li e concordo com os
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="https://google.com"
                        style="text-decoration: none"
                        @click.stop
                        v-on="on"
                      >
                        Termos de Uso e Política de Privacidade
                      </a>
                    </template>
                    Abrir em uma nova aba
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>

            <v-row justify="center" no-gutters>
              <v-col class="d-flex justify-center" cols="12">
                <v-btn
                  block
                  :disabled="!valid || loading"
                  color="primary"
                  class="signup-btn"
                  large
                  rounded
                  type="submit"
                  @click="signup()"
                >
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    size="25"
                    width="3"
                    color="white"
                  />
                  <span v-else class="white--text">Cadastrar</span>
                </v-btn>
              </v-col>

              <v-col cols="12" class="mt-4">
                <v-divider />
              </v-col>

              <v-col cols="12" class="d-flex flex-column align-center mt-4">
                <router-link to="/" class="text-link">
                  Já possui uma conta?
                  <span>Entre!</span>
                </router-link>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <ConfirmSignUp v-else :username="user.username" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  components: {
    ConfirmSignUp: () => import("@/components/SignUpConfirmation"),
  },
  data() {
    return {
      loading: false,
      waiting_confirmation: false,
      valid: true,
      showPassword: false,
      rules: {
        required: (value) => !!value || "Este campo é obrigatório.",
        minLength: (value) =>
          value.length >= 8 || "Informe pelo menos 8 caracteres.",
        username: (value) =>
          /^[a-zA-Z0-9]*$/.test(value) ||
          "Não utilize caracteres especiais e espaços em branco.",
        cpf: (value) =>
          (value && value.length == 14 && this.validateCPF(value)) ||
          "Informe um CPF válido",
        phone: (value) =>
          (value && value.length == 15) ||
          "Informe um número de telefone válido",
        email: (value) => /.+@.+\..+/.test(value) || "Informe um email válido.",
        password: (value) =>
          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) ||
          "Sua senha deve conter no mínimo uma letra maiúscula, 1 número e 1 caractere especial.",
        passwordConfirmation: (value) =>
          (value && value === this.user.password) || "As senhas não coincidem.",
      },
      genders: [
        {
          value: "masculino",
          text: "Masculino",
        },
        {
          value: "feminino",
          text: "Feminino",
        },
        {
          value: "nao_informar",
          text: "Não informar",
        },
      ],
      user: {
        username: "",
        password: "",
        passwordConfirmation: "",
        email: "",
        full_name: "",
        phone_number: "",
        gender: "",
        cpf: "",
      },
      checkbox: false,
    };
  },
  methods: {
    signup() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const user = this.user;
        Auth.signUp({
          username: user.username,
          password: user.password,
          attributes: {
            email: user.email,
            name: user.full_name,
            phone_number: this.cleanPhoneNumber(user.phone_number),
            gender: user.gender,
            "custom:cpf": user.cpf,
          },
        })
          .then(() => {
            this.loading = false;
            this.waiting_confirmation = true;
            this.$toast.success(
              "Informe o código recebido por e-mail para concluir o cadastro!"
            );
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            var message = "Algo de errado aconteceu";
            if (err.name === "UsernameExistsException") {
              message =
                "Já existe uma conta cadastrada com esse nome de usuário.";
            }
            if (err.name === "UserLambdaValidationException") {
              message = err.message.replace("PreSignUp failed with error ", "");
            }
            this.$toast.error(message);
          });
      }
    },
  },
};
</script>

<style scoped>
.signup-box {
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.signup-description p {
  text-align: center;
  margin-bottom: 40px;
  color: #a3a3a3;
}

.signup-btn {
  font-weight: bold;
  letter-spacing: 0 !important;
}

.text-link {
  text-decoration: none;
  color: #a3a3a3;
}

.text-link span {
  font-weight: bold;
  color: #008cd1;
}

.checkbox-text {
  font-size: 13px;
}

.back-btn {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 10;
}
</style>
